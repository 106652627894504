.actions {
  background-color: #5d50ff;
  width: 6rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-date {
  padding: 10px;
  background-color: #f9f9f9;
  overflow-y: auto;
  max-height: 200px; /* Optional: for scrolling */
}

.date {
  font-size: 16px;
  color: #333;
  margin: 5px 0;
}

.date.title {
  color: #ff00ff;
  font-size: 20px;
  font-weight: bold;
}

.actions1 {
  background-color: red;
  width: 7rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.end-line{
  display: flex;
      justify-content: flex-end;
      gap: 1rem;
}
 .react-time-picker__clock--open{
  display: none;
}
 .react-time-picker__inputGroup__hour{
 font-size: 2rem;
}
.react-time-picker__wrapper{
display: flex;
justify-content: center;
align-items: center;

}
.react-datepicker__input-container{
  border: 1px solid rgba(0, 0,0, 0.87);
}
.actions3 {
  background-color: rgb(42, 42, 42);
  width: 9rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* z-index */
.patiend-add{
  border: 2px solid #5d50ff;
  margin-bottom: 1rem;
  border-radius: 10px;
}
.actions31 {
  background-color: rgb(255, 0, 0);
  width: 17rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addbutton {
  background-color: #fff;
  width: 8rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 16px;
  border: 2px solid #5d50ff;
  color: #5d50ff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addbutton:hover {
  background-color: #5d50ff;
  width: 8rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modaltitle {
  font-size: 25px;
  font-weight: 600;
  color: #5d50ff;
}

.buttoninmodal {
  background-color: #5d50ff;
  width: 6rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 14px;
  border: 2px solid #5d50ff;

  align-items: center;
}
.buttoninmodal1 {
  background-color: red;
  width: 6rem;
  height: 2rem;
  display: flex;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 14px;
  border: 2px solid red;
  justify-content: center;
  align-items: center;
}
.custom-box {
  z-index: 0;
  height: 100vh;
}
.inputstyle {
}
.inputstyle {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.green-button {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.red-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.modalview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.selectg {
  margin-bottom: 10px;
  width: 100%;
  height: 2rem;
  border-radius: 2rem;
}
.itembeside {
  display: flex;
  flex-direction: row;
}
.name-column--cell {
  width: 20rem;
}
.login-container {
  text-align: center;
  display: flex;
  /* min-height: 100vh; */
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(79deg, #ff00ff, #5d50ff 48%, #ff00ff);
  height: 100vh;
width: 100%;
}

.auth-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
}
.centerdiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}
.App {
  height: 100vh;
  background-color: black;
}
.loginbutton {
  margin-top: 1rem;
  border-radius: 1rem;
  font-size: 20px;
  font-weight: 800;
  width: 50%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
