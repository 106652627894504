.teeth-32 {
  display: flex;
  flex-direction: column;
}
.teeth-32 .half {
  display: flex;
}

.teeth-32 .half:first-child .quarter {
  border-bottom: 0.01em solid black;
}

.teeth-32 .half:last-child .quarter {
  border-top: 0.01em solid black;
}

.mouth {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.quarter {
  display: flex;
  gap: 0.5rem;
}

.quarter:first-child {
  border-right: 1px solid black;
}

.quarter:last-child {
  border-left: 1px solid black;
}

/* Style for treated arches */
.treated-arches {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.treated-arches span {
  display: flex;
  align-items: center;
}

.treated-arches input[type="radio"] {
  margin-right: 5px;
}

.treated-arches label {
  margin-right: 15px;
  font-size: 16px;
  color: #555;
}

/* Style for tooth move restriction */
.tooth-move-restriction {
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.tooth-move-restriction p {
  margin-bottom: 5px;
  font-size: 16px;
  color: #555;
}

.mouth {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.mouth div {
  flex: 1;
  text-align: center;
}

.teeth-32 {
  display: flex;
  flex-direction: column;
}

.half {
  display: flex;
  justify-content: space-between;
}

.quarter {
  flex: 1;
}

.quarter > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

/* Style for tooth checkboxes */
.teeth-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Style for R and L labels */
.mouth div:first-child,
.mouth div:last-child {
  font-size: 18px;
}

/* .overjet,
.overbite {
  margin-right: 4rem;
} */

.overjet span,
.overbite span {
  display: flex;
  flex-direction: column;
  padding: 0.1rem;
}

.overjet label,
.overbite label {
  margin-left: 0.5rem;
}

/* Radio button styles */
.overjet input[type="radio"],
.overbite input[type="radio"] {
  margin-right: 0.5rem;
}

.bite-ramps {
  margin-top: 20px;
}

.bite-ramps span {
  display: flex;
  flex-direction: column;
  padding: 0.1rem;
}

.bite-ramps div {
  margin-bottom: 0.5rem;
}

.bite-ramps label {
  margin-left: 0.5rem;
}

/* Radio button styles */
.bite-ramps input[type="radio"] {
  margin-right: 0.5rem;
}

.big {
  display: flex;
  justify-content: space-around;
}

h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #5d50ff;
}

.patient-info {
  display: flex;
  margin-bottom: 10px;
  margin-left: 4rem;
  gap: 2rem;
  font-weight: bold;
  color: #000000;
}

.top {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-around;
  margin-top: 3rem;

}

.bottom {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 2rem;
}
